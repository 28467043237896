import React from 'react'

const DarkThemeIcon = ({ id, role, title, focusable, clickHandler }) => {
    return (
        <svg
            id={id}
            role={role}
            tabIndex={focusable ? 0 : null}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 548.087 548.087"
            onClick={clickHandler}
        >
            <title>{title}</title>
            <path d="M189.818 528.559c123.706 52.317 287.13-4.908 339.15-118.961-83.461 60.649-171.422 71.257-260.773 16.145-78.642-48.507-115.884-123.322-104.367-214.266C178.484 95.743 251.691 31.581 365.833 10.818 260.055-22.601 98.724 18.293 36.899 177.213c-47.776 122.828-.081 286.633 152.919 351.346z"/>
            <path d="M350.423 116.556l35.565 34.435-8.197 50.271c-1.257 7.711 3.546 11.465 10.727 8.384l44.048-18.895 44.048 18.895c7.181 3.081 11.717-.624 10.135-8.278l-10.433-50.38 38.106-34.745c5.773-5.263 4.223-10.669-3.464-12.072l-48.111-8.78-23.525-43.313c-3.729-6.866-9.775-6.866-13.505 0l-23.524 43.313-48.116 8.784c-7.687 1.401-9.368 6.942-3.754 12.381zM427.939 323.493l-34.623-6.315-16.932-31.172c-2.685-4.94-7.034-4.94-9.719 0l-16.932 31.172-34.619 6.315c-5.532 1.008-6.74 4.998-2.701 8.911l25.594 24.781-5.899 36.178c-.906 5.549 2.55 8.25 7.72 6.034l31.697-13.595 31.697 13.595c5.169 2.216 8.434-.448 7.291-5.957l-7.507-36.255 27.422-25.002c4.161-3.793 3.043-7.682-2.489-8.69z"/>
        </svg>
    )
}

export default DarkThemeIcon

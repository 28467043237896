import React from 'react'

const LightThemeIcon = ({ id, role, title, focusable, clickHandler }) => {
    return (
        <svg
            id={id}
            role={role}
            aria-describedby={title}
            tabIndex={focusable ? 0 : null}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 208.948 208.948"
            onClick={clickHandler}
        >
            <title>{title}</title>

            <g>
                <path d="M104.474 38.317c-36.479 0-66.158 29.678-66.158 66.157s29.678 66.157 66.158 66.157 66.158-29.678 66.158-66.157-29.678-66.157-66.158-66.157zm0 117.315c-28.208 0-51.158-22.949-51.158-51.157s22.949-51.157 51.158-51.157 51.158 22.949 51.158 51.157-22.949 51.157-51.158 51.157zM103.929 32.228h.052c4.142-.028 7.477-3.409 7.449-7.551l-.117-17.227c-.029-4.143-3.402-7.516-7.551-7.449-4.142.028-7.477 3.409-7.449 7.551l.117 17.227c.028 4.124 3.381 7.449 7.499 7.449z"/>
                <path d="M160.479 55.2c1.937 0 3.873-.745 5.34-2.232l12.098-12.264c2.909-2.949 2.876-7.698-.072-10.606-2.95-2.911-7.698-2.878-10.606.071L155.14 42.433c-2.909 2.949-2.876 7.698.072 10.606 1.461 1.442 3.364 2.161 5.267 2.161zM201.449 96.313h-.052l-17.227.117c-4.142.028-7.477 3.409-7.449 7.551.028 4.125 3.38 7.449 7.499 7.449h.052l17.227-.117c4.142-.028 7.477-3.409 7.449-7.551-.029-4.124-3.381-7.449-7.499-7.449zM166.515 155.141c-2.949-2.911-7.698-2.878-10.606.071-2.909 2.949-2.876 7.698.072 10.606l12.264 12.099c1.461 1.442 3.364 2.161 5.267 2.161 1.937 0 3.873-.745 5.34-2.232 2.909-2.949 2.876-7.698-.072-10.606l-12.265-12.099zM104.967 176.722c-4.142.028-7.477 3.409-7.449 7.551l.117 17.227c.028 4.125 3.38 7.449 7.499 7.449h.052c4.142-.028 7.477-3.409 7.449-7.551l-.117-17.227c-.028-4.143-3.425-7.463-7.551-7.449zM43.13 155.981l-12.098 12.264c-2.909 2.949-2.876 7.698.072 10.606 1.461 1.442 3.364 2.161 5.267 2.161 1.937 0 3.873-.745 5.34-2.232l12.098-12.264c2.909-2.949 2.876-7.698-.072-10.606-2.95-2.911-7.699-2.878-10.607.071zM32.227 104.968c-.028-4.125-3.38-7.449-7.499-7.449h-.052l-17.226.117c-4.142.028-7.477 3.409-7.449 7.551.028 4.125 3.38 7.449 7.499 7.449h.052l17.227-.117c4.141-.029 7.476-3.41 7.448-7.551zM42.433 53.809c1.461 1.442 3.364 2.161 5.267 2.161 1.937 0 3.873-.745 5.34-2.232 2.909-2.949 2.876-7.698-.072-10.606l-12.265-12.1c-2.949-2.912-7.698-2.877-10.606.071-2.909 2.949-2.876 7.698.072 10.606l12.264 12.1z"/>
            </g>
        </svg>
    )
}

export default LightThemeIcon
